import axios from 'axios'

const $http = axios.create({
    baseURL:'/',
    timeout:10000
});

//响应拦截
$http.interceptors.response.use(response=>{
    if(response.status == 200){
        if(response.data.code == 200){
            return response.data;
        }else{
            return Promise.reject(response.data.msg);
        }
    }else{
        return Promise.reject(response.data);
    }
},error=>{
    return Promise.reject(error);
});

export default $http