<template>
    <div class="page-container">
        <div class="top-line">
            <img src="../assets/logo.png" alt="" class="logo">
            <div class="down-txt" @click="jumpDown">已有账号？点击下载</div>
        </div>
        <div class="page-bottom">
            <img src="../assets/register-bg.png" alt="" class="bottom-bg">
            <div class="form-box">
                <div class="form-line">
                    <input type="text" v-model="form.userMobile" placeholder="请输入邮箱" />
                </div>
                <div class="form-line flex-line">
                    <input type="text" v-model="form.verifyCode" placeholder="请输入图形验证码" class="yzm-input"/>
                    <img :src="imgSrc" class="yzm-pic" alt="" @click="getPic">
                </div>
                <div class="form-line flex-line">
                    <input type="text" v-model="form.shortMsg" placeholder="请输入验证码" class="yzm-input"/>
                    <div class="btn-yzm" @click="getMsgCode" v-show="countDownNum == 60">获取验证码</div>
                    <div class="btn-yzm" v-show="countDownNum < 60" style="background-color:#f0eeef">{{countDownNum}}秒后重试</div>
                </div>
                <div class="form-line">
                    <input type="text" v-model="form.mobile" placeholder="请输入您的手机号" />
                </div>
                <div class="form-line">
                    <input type="password" v-model="form.passWord" placeholder="请输入您的密码" />
                </div>
                <div class="form-line">
                    <input type="password" v-model="form.confirmPass" placeholder="请确认您的密码" />
                </div>
                <div class="form-line">
                    <input type="text" v-model="form.inviteCode" placeholder="请输入邀请码" />
                </div>
                <div class="btn-register" @click="confirmRegister">注册</div>

                <div class="form-bottom">
                    <label>
                        <input type="checkbox" v-model="isCheck"> 注册协议 <span @click="jumpHt">《合同模板》</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $http from '@/lib/request'
import md5 from 'md5'

export default {
    data(){
        return {
            imgSrc:null,
            countDownNum:60,
            inteval:null,
            key:null,
            form:{
                verifyCode:null,
            },
            isCheck:true,
        }
    },
    activated(){
        if(this.$route.query.inviteCode){
            this.form.inviteCode = this.$route.query.inviteCode;
        }
        this.getPic();
    },
    deactivated(){
        if(this.inteval){
            clearInterval(this.inteval)
        }
    },
    watch:{
        countDownNum(){
            if(this.countDownNum == 0){
                clearInterval(this.inteval);
                this.countDownNum = 60;
            }
        },
    },
    methods:{
        jumpHt(){
            this.$router.push('/agreement');
        },
        getPic(){
            $http({
                url:'/npcc/user/graphCodeVerify',
                method:'post'
            }).then(res=>{
                this.imgSrc='data:image/png;base64,'+res.data.imgsrc;
                this.key=md5(res.data.imgsrc);
            }).catch(err=>{
                alert(err);
            });
        },
        getMsgCode(){
            $http({
                url:'/npcc/user/getVerifyCodeEmail',
                method:'post',
                headers:{
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                params:{
                    vcodeKey:`vcode${this.key}`,
                    verifyCode:this.form.verifyCode,
                    mobile:this.form.userMobile,
                }
            }).then(res=>{
                this.inteval = setInterval(()=>{
                    this.countDownNum=this.countDownNum - 1;
                },1000);
            }).catch(err=>{
                alert(err);
            });
        },
        confirmRegister(){
            if(this.form.passWord != this.form.confirmPass){
                alert('两次输入密码不一致');
                return;
            }
            if(!this.isCheck){
                alert('请勾选协议');
                return;
            }
            $http({
                url:'/npcc/user/registerCodeEmail',
                method:'post',
                headers:{
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                params:{
                    userMobile:this.form.userMobile,
                    shortMsg:this.form.shortMsg,
                    inviteCode:this.form.inviteCode,
                    passWord:md5(this.form.passWord),
                    bingMobile:this.form.mobile,
                }
            }).then(res=>{
                this.$router.push({
                    name:'download'
                });
            }).catch(err=>{
                alert(err);
            });
        },
        jumpDown(){
            this.$router.push({
                name:'download'
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    background-color: #000;
    min-height: 100vh;
    .top-line{
        height:55px;
        line-height: 55px;
        padding: 0 20px;
        background-color: #A28324;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo{
            width:103px;
            height: 29px;
        }
        .down-txt{
            font-size: 14px;
            color: #fff;
        }
    }
    .page-bottom{
        padding-top: 15px;
        padding-left: 25px;
        .bottom-bg{
            display: block;
            width: 100%;
        }
        .form-box{
            background-color: #fff;
            border-radius: 12px;
            width:325px;
            padding:46px 18px;
            padding-bottom: 20px;
            box-sizing: border-box;
            position: relative;
            top:-80px;
            .form-line{
                line-height: 44px;
                height: 44px;
                margin-bottom: 15px;
                &.flex-line{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .btn-yzm{
                    width: 110px;
                    height: 44px;
                    line-height: 44px;
                    background: #F0B80D;
                    border-radius: 22px;
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                }
                input{
                    border:none;
                    line-height: 44px;
                    height: 44px;
                    background-color: #f0eeef;
                    padding:0 20px;
                    border-radius: 22px;
                    outline: none;
                    width: 100%;
                    margin:0;
                    box-sizing: border-box;
                }
                .yzm-input{
                    width:170px;
                }
                .yzm-pic{
                    width: 110px;
                    height: 44px;
                    background-color: #fff;
                }
            }
            .btn-register{
                width: 290px;
                height: 44px;
                line-height: 44px;
                text-align: center;
                background: #F0B80D;
                border-radius: 22px;
                font-size: 16px;
                color:#fff;
                margin-top: 30px;
                margin-bottom: 50px;
            }
            .form-bottom{
                font-size: 14px;
                color: #333;
                label{
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    input{
                        margin-right: 10px;
                    }
                    span{
                        color:#F0B80D;
                    }
                }
            }
        }
    }
}
</style>