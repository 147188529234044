<template>
    <div class="page-container">
        <div class="btn" @click="downApp">点我下载</div>
        <!-- <a href="itms-services://?action=download-manifest&url=https://gitee.com/wts90/plist/blob/master/download.plist">测试按钮</a> -->
        <img src="../assets/phone-pic.png" alt="" class="phone-pic">
        <div class="mask" v-show="isWx">
            <img src="../assets/wx-mask.png" class="wx-mask" alt="">
        </div>
    </div>
</template>

<script>
import $http from '@/lib/request'

export default {
    data(){
        return {
            isWx:false,
            androidDownload:null,
            iosDownload:null,
        }
    },
    activated(){
        $http({
            url:'/npcc/version/getH5Version',
            method:'post'
        }).then(res=>{
            this.iosDownload = res.data.iosDownload;
            this.androidDownload = res.data.androidDownload;
        });
    },
    deactivated(){
        this.androidDownload=null;
        this.iosDownload=null;
    },
    methods:{
        downApp() {
            if(!this.iosDownload || !this.androidDownload){
                $http({
                    url:'/npcc/version/getH5Version',
                    method:'post'
                }).then(res=>{
                    this.iosDownload = res.data.iosDownload;
                    this.androidDownload = res.data.androidDownload;
                    this.jumpDownload();
                });
            }else{
                this.jumpDownload();
            }
        },
        jumpDownload(){
            let ua = navigator.userAgent.toLowerCase();
            
            //Android终端
            // let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;  
    　　   //Ios终端
            // let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); 

            if (isWeixinBrowser()) {
                this.isWx = true;
            } else {
                this.isWx = false;
                if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                    //Ios
                    // window.location= 'itms-services://?action=download-manifest&url=https://github.com/hao1208hao/ios-plist/blob/main/download.plist'
                    // window.open('itms-services://?action=download-manifest&url=https://raw.githubusercontent.com/hao1208hao/ios-plist/main/download.plist','_self');
                    window.location = this.iosDownload;
                } else if (/(Android)/i.test(navigator.userAgent)) {
                    //Android终端
                    window.location = this.androidDownload;
                }
            }

            function isWeixinBrowser() {
                return (/micromessenger/.test(ua)) ? true : false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.page-container{
    background-color: #000;
    background-image: url('../assets/download-bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    .btn{
        width: 180px;
        height: 44px;
        line-height: 44px;
        background: #F0B80D;
        text-align: center;
        border-radius: 22px;
        color:#fff;
        font-size: 17px;
        position: absolute;
        top:226px;
        left:50%;
        transform: translateX(-50%);
    }
    .phone-pic{
        width: 300px;
        position: absolute;
        bottom:-100px;
        left:50%;
        transform: translateX(-50%);
    }
    .mask{
        position: fixed;
        left:0;
        top:0;
        background-color: rgba(0,0,0,.9);
        z-index: 9;
        width: 100vw;
        height:100vh;
        .wx-mask{
            width:236px;
            height:275px;
            position: absolute;
            top:15px;
            right:15px;
        }
    }
}
</style>