<template>
    <div class="page-container">
        <div class="page-title">注册声明</div>
        <div class="page-content">
            <p>
                本声明不涉及以及承担NPC经营性和NPCC相关风险，不涉及任何在司法管制内的受管制产品。本应用是NPC社区志愿者发起并建立的NPCC生态共识平台，用于持有NPC相关通证的志愿者之间的数字资产交换、抵押与处置。本应用内的内容不能作为招股说明书或其他任何形式的标准化合约文件，也并不是构成任何司法管辖区内的证券或其他任何受管制产品的劝告或征集的投资建议。在本应用中所呈现的任何信息或者分析，都不构成任何参与代币投资决定的建议，并且不会做出任何具有倾向性的具体推荐。
            </p>
            <p>
                NPCC是区块链网络的虚拟密码学代币。NPCC不是投资品，没有任何能够保证,也没有任何理由相信，你所持有的NPCC将会一定升值，甚至有可能存在贬值的风险。 NPCC不是所有权证明或具有控制权，持有NPCC及相关代币并不是授予持有者所有权以及 NPC⽹络系统的股权，仅代表参与NPCC社区治理和生态发展的通证，并不是授予其直接控制或者替NPC网络系统做任何决策的权利。
            </p>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return {}
    },   
}
</script>

<style lang="scss" scoped>
.page-container{
    color:#333;
    .page-title{
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        line-height: 36px;
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .page-content{
        padding:0 10px;
        p{
            text-indent: 20px;
            margin-bottom: 20px;
            line-height: 26px;
            font-size: 16px;
        }
    }
}
</style>